<template>
  <div>
    <b-row>
      <b-col lg="6">
        <h2 class="mb-4 font-weight-bold">
          <small><i class="fe fe-file"></i></small> Editor Choices <a-divider type="vertical" />
          <span v-if="user.portals.length && settings.activePortal" class="text-muted small">
            {{ user.portals.filter(portal => portal.id === settings.activePortal)[0].title}}
          </span>
        </h2>
      </b-col>
      <b-col class="text-lg-right mb-4 mb-lg-0" lg="6">
        <b-button variant="success" :disabled="!editorChoicesForm.portal_id || !editorChoicesForm.post_id.length" @click.prevent="updateEditorChoices">
          Save Editor Choices
        </b-button>
      </b-col>
    </b-row>
    <router-view />
  </div>
</template>
<script>
import { mapState } from 'vuex'
// import Acl from '@/components/cleanui/system/ACL'
export default {
  computed: {
    ...mapState(['settings', 'user']),
    ...mapState('editorChoices', {
      editorChoicesForm: state => state.form,
    }),
  },
  components: {
    // Acl,
  },
  methods: {
    updateEditorChoices(ids) {
      const vm = this
      vm.$store.dispatch('editorChoices/UPDATE').then(() => {
        vm.$notification.success({
          message: 'Editor Choices Updated',
          description: 'Successfully updated.',
        })
      })
    },
  },
}
</script>
